.navbar-header {
  background: white;
  padding: 10px 20px;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.welcomeheader {
  margin-left: 0;
}
.headercom {
  display: flex;
}

.ant-layout-sider-collapsed {
  display: none;
}

@media only screen and (min-width: 300px) and (max-width: 765px) {
  .fa-bars {
    display: block !important;
  }
  /* .side-nav-parent{
      width:76% !important;
     } */
  .ant-layout-sider {
    position: fixed !important;
  }
}
@media only screen and (min-width: 766px) and (max-width: 2000px) {
  .ant-layout-sider-collapsed {
    display: block;
  }
  .fa-bars {
    display: none !important;
  }
}

.navbar-title {
  margin-left: 250px;
  font-size: 1.8rem;
  color: #333;
}
.welcomeheader {
  /* margin-left: 20px; */
}
.navbar-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-avatar {
  background-color: #87d068;
}

.navbar-user-text {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.navbar-user-subtext {
  font-size: 12px;
  color: #888;
}

/* Responsive styles for mobile screens (max-width: 768px) */
@media (max-width: 768px) {
  .navbar-header {
    flex-direction: row; /* Stack elements vertically */
    align-items: center; /* Center-align elements */
    /* padding: 10px; */
    display: flex;
  }
  .ant-layout-header {
    /* width: 30rem; */
    /* border: solid 1px red; */
  }

  .navbar-title {
    margin-left: 0; /* Remove extra margin for smaller screens */
    font-size: 1rem; /* Slightly smaller title font size */
    text-align: center; /* Center-align the title */
    width: 100%; /* Ensure the title spans full width */
  }

  .navbar-user-info {
    margin-top: 10px; /* Add spacing between title and user info */
    justify-content: center; /* Center-align user info section */
  }

  .navbar-avatar {
    margin: 0 auto; /* Center the avatar */
  }
  .welcomeheader {
    margin-left: 20px;
    font-size: 5px;
    /* border:solid 1px red; */
  }
  .navbar-user-text,
  .navbar-user-subtext {
    text-align: center; /* Center-align text */
  }
}
