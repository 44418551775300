/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* .wholecontainer{
    width: 100;
    height: 100vh;
    border: solid 2px red;

} */

/* .logincontainer{
    background: linear-gradient(180deg,#01ACC3,#00535E);
}
.bordercontainer{
    border:'solid 2px red'
}
.loginwidth{
    width: 100rem;
    height: 10rem; */
/* }
.custom-login-width {
    width: 400px;
    height: 450px;
  } */
  
  /* @media (max-width: 768px) {
    .custom-login-width {
      width: 90%; /* Adjust width for smaller screens */
    /*  height: auto; /* Adjust height as needed */
    /* }
  } */
   /* .textgradiant */
/* { 

    background-color: white;
   
    background: linear-gradient(to right,#01ACC3,#00535E);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}  
.textfam{
    font-family: "Poppins", sans-serif;
    font-weight: '200';

} */ 

/* .gg{
    width: 156px;
height: 140px;
top: 160px;
left: 996px;
gap: 0px;
border-radius: 39px 0px 0px 0px;
opacity: 0px; */


/* .rr{
    width: 484px;
height: 524px;
top: 330px;
left: 832px;
gap: 0px;
opacity: 0px;

} */ 
 .anticonanticon-eye-invisibleant-input-password-icon{
    border: solid 1px red;
 }

.anticon .anticon-eye .ant-input-password-icon{
        display:none;
}

.ant-radio-checked .ant-radio-inner{
    border-color: #00ADC4 !important ;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: #00ADC4;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: red ;
  }
  .password {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px; /* Create space for the eye icon */
    font-size: 16px;
  }
  
  .password .ant-input-suffix {
    position: absolute;
    right: 10px; /* Align the eye icon to the right */
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px; /* Adjust icon size if needed */
    color: #888;
  }
  #signin_password{
    margin-right: 50px;
   }