.ant-steps-item-process .ant-steps-item-icon {
  background-color: #00adc4 !important;
  border: transparent;
  margin-top: -6px !important;
  border: 8px solid #ecf0f3 !important;
  width: 50px !important;
  height: 50px !important;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  top: 0.5px !important;
}
.ant-steps-item-tail::after {
  /* margin-inline-start: 10px !important; */
  border: 1px dashed #c5c4c4 !important;
  /* border-width: 1px !important; */
  background: transparent !important;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  border-color: #0defef !important;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fffefe !important;
}

/* Styles.css */
.radio-label {
  margin-right: 8px;
  font-weight: bold;
  font-size: 16px;
}

.radio-group {
  margin-left: 12px;
}
.addiconreg {
  filter: drop-shadow(18px 18px 30px #d1d9e6) drop-shadow(-18px -18px 30px #fff);
}

/* Change the border color of the radio button */
:where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper
  .ant-radio-checked::after {
  border-color: #00adc4; /* Customize border color */
}

/* Change the outer border color when the radio button is checked */
.ant-radio-checked .ant-radio-inner {
  border-color: #00adc4; /* Teal border color */
}

.ant-radio-checked .ant-radio-inner::after {
  transform: scale(-0.625) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #e6f7ff; /* Optional: Adjust the background color */
  border-color: #91d5ff; /* Optional: Adjust the border color */
}

/* .ant-steps-item-finish .ant-steps-icon {
  display: none; /* Hides the tick mark */
/*} */

:where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  /* border-color: #1677ff; */
  background-color: #ececec;
}

/* Remove or override any default blue styles */
.ant-radio-checked {
  color: #00adc4; /* Ensure the checked state does not apply any blue */
}

/* Customize the hover effect */
.ant-radio:hover .ant-radio-inner {
  border-color: #00adc4; /* Ensure consistent hover border color */
}
.regprebtng {
  margin-right: 10px;
  color: white;
  /* width: 10% !important; */
  background-image: linear-gradient(102.24deg, #00adc4 -16.3%, #00535e 164.53%);
}
