.empty-data-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .empty-data {
    margin-bottom: 20px;
  }
  
  .empty-data-button {
    background-color: #017b8a;
    border-color: #017b8a;
    color: white;
  }
  
  .empty-data-button:hover {
    background-color: #015f6a;
    border-color: #015f6a;
  }
  