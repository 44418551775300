/* Custom styles for the Select dropdown options */
.custom-select .ant-select-item-option {
    padding: 8px 16px;
    margin: 4px 0;
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .custom-select .ant-select-item-option-active {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0; /* Light background on hover */
  }
  
  /* Optional styling to smooth transition */
  .custom-select .ant-select-item-option {
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
  }
  