.imageshadow {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 220px;
  height: 220px; */
  /* border-radius: 50%; */
  /* margin: 10px; */
}
.calss-upload-logo {
  box-shadow: 2px 2px 4px 0px #e0e2e4, -2px -2px 4px 0px #f8f9fa;
}
.Wholeinstitutelogo {
  box-shadow: -8px -8px 16px 0px #f8f9fa inset, 8px 8px 16px 0px #e0e2e4 inset;
  border-radius: 5px;
  /* border: solid 1px red; */
  /* border-radius: 50%;
  width: 250px;
  height: 220px; */
}

.Wholeinstitutelogoimage {
  width: 70px;
  /* height: 200px; */
  margin: 6px;
  /* margin-top: 10px;
  margin-left: 10px; */
  /* border: solid 1px red;   innershadow:*/
  border-radius: 5px;
  object-fit: cover;
  /* opacity: 60%; */
  filter: drop-shadow(18px 18px 30px #d1d9e6) drop-shadow(-18px -18px 30px #fff);

  box-shadow: 8px 8px 16px 0px #d1d9e6 inset, -8px -8px 16px 0px #ffffff inset;
}

/* .ant-input ,.ant-col{
    width: 300px;
} */

.insaddbtnacc {
  background: linear-gradient(to right, #00adc4 0%, #00535e 100%) !important;
  margin-right: 0px;
}
.themehandlerinput.ant-input {
  width: 50px !important;
}

.themeinputbox {
  height: "40px" !important;
  border: "none";
  cursor: "pointer";
  width: "15rem";
  position: "absolute";
  margin-left: "60px";
}
.themehandlerinput {
  /* width: "50px" !important; */
  height: "40px";
  border: "none";
  cursor: "pointer";
  position: "relative";
}
.institutecontainer {
  /* width: 100%; */
  padding: 20px;
  margin: 15px;
  background: #ecf0f3;
  border-radius: 8px;
  box-shadow: 8px 8px 13px 0px #d1d9e6, -8px -8px 16px 0px #ffffff;
  /* margin-top: 2rem;
  margin: 0 20px 0 20px; */
}

.boxshadowinstitute {
  box-shadow: 8px 8px 16px 0px #d1d9e640, -8px -8px 16px 0px #ffffff;
  /* border: solid 1px #ffffff; */
}
input[type="text"] {
  background-color: #ffffff;
}
