.eventTabContainer .ant-tabs-nav {
  border: none !important; /* Remove default underline */
}

.eventTabContainer .ant-tabs-tab {
  margin: 0 !important; /* Remove extra margins */
}

.eventbtn {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
}

.wholecontainer .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  background-color: #00adc4;
  padding: 4px;
  border-radius: 2px;
}
.wholecontainer .ant-tabs .ant-tabs-tab:hover {
  color: #00adc4;
}
.wholecontainer .ant-tabs-nav-list {
  /* border: solid 1px; */
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa !important;
  background-color: #ecf0f3;
  font-family: "poppins";
  height: 40px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.wholecontainer .ant-tabs-nav-list {
  /* border: solid 1px; */
  width: 100%;
  text-decoration: none;
  justify-content: space-evenly;
}
.wholecontainer .ant-tabs-tab {
  color: #000;
  /* height: 2rem; */
}
.wholecontainer .ant-tabs-tab-active {
  text-decoration: none !important;
  outline: none;
  border-bottom: none !important;
}
.wholecontainer .ant-tabs-nav {
  text-decoration: none !important;
  text-decoration: none !important;
  outline: none;
}
.wholecontainer .ant-tabs .ant-tabs-ink-bar {
  background: none;
}
