.Drivercomponentcon::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
}

  .Drivercomponentcon{
   
        /* border:solid 1px red; */
         background-Color: "#f6f7f9";
        font-Family: 'poppins';
        font-Weight: 400; 
        /* width: 100%; */
        overflow: auto; /* Keeps scrolling enabled */
        scrollbar-width: none; /* For Firefox */
        padding: 5px;
  }

  .drivertablesearch{
    /* border: solid 1px blue; */
text-align: center; 
 }