.background {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ecf0f3;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff;
}
.ant-popover .ant-popover-inner {
  background: #f7fafd !important;
  box-shadow: "0 4px 10px rgba(0, 0, 0, 0.1)";
}
body {
  background-color: #f5f5f5 !important;
}
.navheader {
  display: flex;
  flex-direction: column;
}
.table-datas {
  th,
  td {
    white-space: nowrap;
  }
}
.toggleicon {
  position: absolute;
  bottom: 2%;
  left: 8%;
  /* background: #00adc4; */
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
  /* width: 30px; */
}
/* .toggleicon:active{
  rotate: 30deg !important;
} */
.toggleicon {
  transition: transform 0.3s ease; /* Smooth rotation */
}

.toggleicon.rotated {
  transform: rotate(180deg); /* Rotate 180 degrees */
}
.welcomeheader {
  /* margin-left: 300px !important; */
}
.ant-pagination-item-active {
  background-color: transparent !important;
  color: #ffffff !important;
}
.ant-layout {
  background-color: transparent !important;
}
.ant-picker-calendar-mini.td {
  align-items: unset !important;
}
/* @media (max-width: 1350px) {
  .ant-layout.ant-layout-has-sider {
    width: fit-content !important;
  }
} */
@media (max-width: 767px) {
  .toggleicon {
    display: none !important;
  }
  .ant-table-tbody {
    font-size: 10px !important;
  }
  th,
  td {
    padding: 5px !important;
    /* text-align: left; */
  }
  .outletinnercontainer {
    padding: 0% !important;
  }
  .classinfocontainer {
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }
  .btnacccon .ant-pagination .ant-pagination-disabled,
  .pagination_align .ant-pagination .ant-pagination-disabled {
    padding: 0px 5px !important;
  }
  .pagination_align {
    justify-content: center !important;
  }
  .ant-pagination .ant-pagination-options {
    position: absolute !important;
    top: 60px !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: fit-content !important;
  }
}
.ant-pagination.custom-pagination {
  /* background: #fff; */
  width: max-content;

  .ant-pagination-item {
    height: 40px !important;
    font-family: "Inter";
    display: flex !important;
    align-items: center !important;
    color: #393a4b;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0px;
    border-width: 1px 1px 1px 0px;
    border-radius: 0px;
    width: 35px;
    height: 35px;
    line-height: 27px;
    &:hover {
      background-color: #edeffd;
    }
    a {
      padding: 4px 6px;
    }
  }
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    .ant-pagination-item-container {
      .ant-pagination-item-link-icon {
        color: var(--primary);
      }
      .ant-pagination-item-ellipsis {
        top: 1px;
        left: -5px;
        color: #393a4b;
      }
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    border: 1px solid #d2d3e0;
    border-width: 1px 1px 1px 0px;
    border-radius: 0px;
    margin: 0px;
    height: 35px;
    overflow: hidden;
  }
  .ant-pagination-item-active {
    color: #4d4f69;
    background-color: #f0f3f6;
  }
  .ant-pagination-disabled .ant-pagination-item-link {
    color: #ffffff;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 25px;
    height: 35px;
    padding: 7px 16px;
    box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80;
    color: #393a4b;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0px;
    &.ant-pagination-disabled {
      display: flex !important;
      align-items: center !important;
      height: 40px;
      background-color: #f0f3f6;
      color: #393a4b5b;
    }
    &:hover {
      background-color: #e8e8e8;
      .ant-pagination-item-link {
        background-color: transparent;
      }
    }
  }
  .ant-pagination-prev {
    border-radius: 8px 0px 0px 8px;
  }
  .ant-pagination-next {
    border-radius: 0px 8px 8px 0px;
    border-left: none;
  }
  .ant-pagination-options {
    margin-inline-start: 12px;
    display: flex;
    .ant-select:hover {
      .ant-select-selector {
        border-color: #008a9c;
      }
    }
    .ant-select-single {
      height: 35px;
      .ant-select-selector {
        border: 1px solid #d2d3e0;
      }
      .ant-select-selection-item {
        color: #393a4b;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

.siderscroll::-webkit-scrollbar {
  display: none;
}
.textformat {
  margin-left: 10px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  padding-top: 20px;
}

.siderscrol {
  transition: all 0.3s ease;
}

.siderscrol .fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
}

.siderscroll {
  background-color: #00adc4;
  color: #ffff;
  font-family: "poppins";
  height: 80vh;
  overflow-y: auto;
}
.headerslide {
  display: flex;
  position: relative;
  justify-content: center;
  height: 10%;

  padding: 10px;
}
.pagination_align {
  display: flex;
  column-gap: 25%;
  justify-content: flex-start;
  align-items: center;
}
.logotypo {
  height: 30px;
  /* border: solid 1px red; */
  width: auto;
}
.slidericon {
  width: 40px;
  height: 30px;
  font-size: 20px;
  /* background-color: #00a5b8; */
}
.whitebagcontainer {
  margin-top: 20px;
  background: #f7f7f7;
  border-radius: 5px;
  padding: 27px 15px 0px 15px;
  border: 4px solid #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
}
.student_address textarea {
  border: 1px solid #dddddd;
  border-radius: 6px;
  outline: none;
}
@media screen and (max-width: 577px) {
  .ant-pagination-options {
    /* border: solid 1px red; */
    position: unset !important;
  }
}

.file-size-info {
  font-size: 12px;
  color: #888;
}

.dropdowncon {
  border: 1px solid #dddddd !important;
  background-color: #f4f4f4 !important;
  border-radius: 5px;
  padding-left: 6px;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: #00adc4;
  text-align: justify;
  color: #fff;
}

.uploadd textarea {
  background-color: #f4f4f4 !important;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Class_alignment.ant-form-item .ant-form-item-control {
  width: 100% !important;
}
/* Hide arrows in Firefox */
.no-arrows {
  -moz-appearance: textfield;
}

@media (max-width: 800px) {
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: #00adc4;
    text-align: center !important;
    color: #fff;
  }
}

.ant-table-wrapper .ant-table-thead > tr > th:active {
  background: #00adc4 !important;
  color: #fff;
}

.ant-table-wrapper .ant-table-thead > tr > th:hover {
  background: #00adc4 !important;
}

.errorlabelhandle {
  color: #ff4d4f;
  margin: 0;
  padding: 0;
}

.custom-select .ant-select-selector:focus {
  outline: none !important;
  box-shadow: none !important;
}

.custom-select .ant-select-selector {
  background-color: #f4f4f4 !important;
  border-radius: 6px;
  border: none !important;
  box-shadow: none !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  padding: 0 18px;
  width: 100%;
}
.ant-btn-variant-outlined {
  border: 1px solid #ec7c74;
  background: #ec7c74;
  color: #ffffff;
  border: none;
}
.ant-btn-variant-solid {
  /* background: linear-gradient(to right, #00adc4 0%, #00535e 100%) !important; */
  color: #ffffff;
  border: none;
}
.custom-confirm {
  /* border: solid 1px red; */
  display: flex;
}

.inputboxfms {
  background: #f4f4f4;
  width: 100% !important;
}

.inputboxfms:focus {
  background: #f4f4f4;
  border: solid 1px #e7e7e7;
}

.inputboxfms:hover {
  background: #f4f4f4;
  border: solid 1px #dddddd;
}

.ant-input-outlined {
  border: 1px solid #e7e7e7;
}

.inputboxfms .ant-input-outlined:focus {
  border: none !important;
}
/* .ant-table-content {
  width: 100% !important;
  overflow: scroll;
} */
.form-buttons {
  display: flex;
  margin-top: 20px;
  justify-content: end;
}

@media (max-width: 768px) {
  .table-size {
    width: 100% !important;
  }
  .custom-pagination {
    display: none;
  }
}
@media (max-width: 800px) {
  .ant-card-body {
    padding: 5px !important;
  }
  .ant-table-content {
    width: 100% !important;
    overflow: scroll;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .table-size {
    width: 100% !important;
  }
}
@media (max-width: 800px) {
  .form-section {
    padding: 10px;
  }
  .tableinnercontainer {
    flex-direction: column !important;
  }
  .tablecontainer {
    padding: 0px !important;
  }

  /* .form-buttons {
    flex-direction: column;
    gap: 10px;
  } */

  .ant-form-item {
    margin-bottom: 12px;
  }
  .tableChart-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
  }
  .login-content {
    display: flex;
    flex-direction: column;
  }
  .field_input {
    width: 100% !important;
  }
  .data-section {
    align-items: center;
  }
  .login-right-img {
    height: 70% !important;
  }
  .ant-table-thead > tr > th {
    background-color: #00adc4 !important;
    text-align: center;
    color: #fff !important;
    font-size: 8px !important;
    padding: 16px 0px !important;
  }
  .seachAccadamicemployee {
    width: 100% !important;
  }
  .ant-input-outlined {
    width: 100% !important;
  }
  .ant-modal-footer {
    display: flex !important;
  }
  .ant-tag {
    font-size: 14px;
    padding: 1px 2px !important;
  }
  .table-size {
    width: 100% !important;
  }
  h2 {
    font-size: 12px !important;
  }
}
@media (max-width: 405px) {
  .admin-contect {
    align-items: flex-end !important;
  }
  .head-dept {
    flex-direction: column;
  }
}
.Acadamicbtn {
  margin-left: 50rem;
}

.scroll::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.backtologin {
  margin-right: 10px;
  background: linear-gradient(to right, #00adc4 0%, #00535e 100%) !important;
  border: none !important;
  color: white !important;
}
.forgetpassword .ant-typography a {
  color: #00535e;
}
.backtologin a {
  text-decoration: none;
  color: white;
}
.modifybutton {
  margin-right: 10px;
  background: linear-gradient(to right, #00adc4 0%, #00535e 100%) !important;
  border: none !important;
  color: white !important;
}
@media only screen and (min-width: 771px) and (max-width: 1235px) {
  .class-student-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .table-size {
    width: 100% !important;
  }

  /* @media (max-width: 480px) {
    :where(.css-dev-only-do-not-override-apn68).ant-typography {
      display: none;
    }
  } */

  @media (max-width: 768px) {
    .table-size {
      width: 100% !important;
    }
  }

  @media (max-width: 1080px) {
    .ant-card-body {
      padding: 0px 24px !important;
    }
  }

  @media only screen and (min-width: 1300px) and (max-width: 1400px) {
    .table-size {
      width: 100% !important;
    }
  }

  @media only screen and (min-width: 1300px) and (max-width: 1400px) {
    .table-size {
      width: 100% !important;
    }
  }

  @media (max-width: 800px) {
    .form-section {
      padding: 10px;
    }

    .tableinnercontainer {
      flex-direction: column !important;
    }

    .tablecontainer {
      padding: 0px !important;
    }

    /* .form-buttons {
      flex-direction: column;
      gap: 10px;
    } */

    .ant-form-item {
      margin-bottom: 12px;
    }

    .tableChart-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100% !important;
    }

    .login-content {
      display: flex;
      flex-direction: column;
    }

    .field_input {
      width: 100% !important;
    }

    .data-section {
      align-items: center;
    }

    .login-right-img {
      height: 70% !important;
    }

    .ant-table-thead > tr > th {
      background: #00adc4 !important;
      text-align: center;
      color: #fff !important;
      font-size: 8px !important;
      padding: 16px 0px !important;
    }

    .ant-table-tbody > td {
      font-family: "poppins";
      font-size: 8px !important;
      padding: 16px 6px !important;
      align-items: center !important;

      span {
        font-size: 8px !important;
        display: flex !important;
        text-align: center !important;
        flex-direction: column !important;
      }
    }

    .ant-tag {
      .table-size {
        width: 100% !important;
      }

      h2 {
        font-size: 16px !important;
      }

      font-size: 14px;
      padding: 1px 2px !important;
    }

    .table-size {
      width: 100% !important;
    }

    h2 {
      font-size: 12px !important;
    }
  }
  @media (max-width: 991px) {
  }
  @media (max-width: 405px) {
    .admin-contect {
      align-items: flex-end !important;
    }

    .head-dept {
      flex-direction: column;
    }
  }

  .Acadamicbtn {
    margin-left: 50rem;
  }

  .scroll::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: none;
  }

  .modifybutton {
    margin-right: 10px;
    background: linear-gradient(to right, #00adc4 0%, #00535e 100%) !important;
    border: none !important;
    color: white !important;
  }

  @media (max-width: 1080px) {
    .Tabcontainer .ant-tabs-nav {
      height: 180px;
    }
    .tablecontainers .ant-tabs-nav,
    .ant-tabs-nav-list {
      height: 100%;
      display: flex !important;
      /* flex-direction: row !important; */
    }
    .ant-tabs-nav-list {
      display: flex;
      flex-direction: column;
    }

    .ant-tabs-tab {
      margin: 0% !important;
    }
  }
}
table {
  table-layout: unset !important;
}

@media (max-width: 477px) {
  .seachAccadamicemployee {
    width: 100%;
  }

  .selectwithbtn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.uploadbackground {
  background: linear-gradient(to right, #00adc4 0%, #00535e 100%) !important;
  border: none !important;
  color: white !important;
  align-items: center;
}
.ant-input-affix-wrapper {
  width: -webkit-fill-available;
}
.circuarwholeopr {
  padding: 50px;
  margin: 30px 0 0px 50px;
  border-radius: 8px;
  width: 90%;
  height: 80vh;
  overflow: auto;
  background-color: #ecf0f3;
  box-shadow: 0px 1px 4px 0px #00000040 !important;
  border: 3px solid #ffffff;
}
.Accodamicwholeopr {
  padding: 10px;
  /* margin: 50px 0 0px 50px; */
  border-radius: 8px;
  width: 100%;
  overflow: auto;
  background-color: #ecf0f3;
  box-shadow: 0px 1px 4px 0px #00000040 !important;
  border: 1px solid #ffffff;
}
.search-id {
  display: flex;
  justify-content: center;
}
@media (max-width: 770px) {
  .nav-sectionBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .toppagination {
    display: none;
  }
  /* .tableinnercontainer{
    display: flex !important;
    justify-content: center !important;
  } */
}
.Accadamich3 {
  margin: 20px 0;
  font-family: "Poppins";
  font-weight: 500;
}

.inputbox {
  background: #ffffff;
  width: 100% !important;
}

.inputbox:focus {
  background: #f4f4f4;
  border: solid 1px #dddddd;
}

.inputbox:hover {
  background: #f4f4f4;
  border: solid 1px #dddddd;
}

.inputbox .ant-input-outlined:focus {
  border: none !important;
  width: 100% !important;
}

.inputbox .ant-input-outlined:active {
  border: none !important;
  outline: none !important;
  background: #f4f4f4;
}

.inputboxempadd {
  padding-top: 8px;
  background: #ffffff;
  width: 100% !important;
}

.inputboxempadd:active {
  background: #f4f4f4;
}

.inputboxempadd:focus {
  background: #f4f4f4;
  border: solid 1px #dddddd;
}

.inputboxempadd:hover {
  background: #f4f4f4;
  border: solid 1px #dddddd;
}

.inputboxempadd .ant-input-outlined:focus {
  border: none !important;
}

.inputboxempadd .ant-input-outlined:active {
  border: none !important;
  outline: none !important;
  background: #f4f4f4;
}

.inputbox.ant-select-outlined .ant-select-selector {
  background-color: #ffffff !important;
}

.inputbox .ant-select-selector {
  background: #f4f4f4 !important;
}

.seachAccadamicemployee {
  height: 40px !important;
  width: 300px !important;
  border: none;
  border-radius: 5px;
  background-color: #ecf0f3 !important;
  margin: 20px;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80 !important;
}
.upload {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.uploadd {
  /* justify-content: center; */
  display: flex;
  align-items: center;
  /* margin-left: 50px; */
}

.seachAccadamicemployee:active {
  background-color: #ecf0f3;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80;
  outline: none;
}

.seachAccadamicemployee:focus {
  background-color: #ecf0f3;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80;
  outline: none;
}

.seachAccadamicemployee:hover {
  background-color: #ecf0f3;
  box-shadow: none;
}

.ant-input-prefix {
  color: #bfbfbf;
  font-size: 20px;
}

.addfilterback {
  /* width: 90%; */
  margin-right: 10px;
  display: flex;
  margin-left: 15rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.backgrdbtnfilter {
  width: 20%;
  border: solid 1px rgb(0, 255, 13);
  margin-left: 20rem;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;
}

.backgrdbtnfilters {
  width: 60%;
  margin-right: 10px;
  /* border: solid 1px #77ff00; */
  display: flex;
  margin-left: 15rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.tablecontainer {
  /* padding: 10px; */
  background-color: #ecf0f3;
  font-family: "poppins";
  font-weight: 400;
  margin-bottom: 40px;
  border-radius: 10px;
  position: relative;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  box-shadow: 8px 8px 16px 0px #d1d9e6, -8px -8px 16px 0px #ffffff;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}
.btnacccon .ant-pagination,
.pagination_align .ant-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(235, 239, 242);
  box-shadow: rgb(209, 217, 230) 18px 18px 30px 0px,
    rgb(255, 255, 255) -18px -18px 30px 0px;
  font-family: Poppins;
  border-radius: 5px;
  gap: 6px;
}
/* .btnacccon .ant-pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: rgb(230 230 230) !important;
  box-shadow: rgb(209, 217, 230) 18px 18px 30px 0px,
    rgb(255, 255, 255) -18px -18px 30px 0px important;
  font-family: Poppins important;
  border-radius: 5px important;
  gap: 6px important;
} */

.btnacccon .ant-pagination .ant-pagination-item {
  display: flex;
  align-items: center;
}
.btnacccon .pagination_align .ant-pagination .ant-pagination-item-active,
.pagination_align .ant-pagination .ant-pagination-item-active {
  display: contents;
}
/* .ant-pagination
  .ant-pagination-item-active
  a */
.ant-pagination .ant-pagination-item-active a {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 0px 4px;
  border: none;
  color: white;
  cursor: pointer;
  font-family: Poppins;
  background-color: rgb(6, 182, 212);
}
.btnacccon .ant-pagination .ant-pagination-disabled,
.pagination_align .ant-pagination .ant-pagination-disabled {
  height: 32px;
  padding: 0px 16px;
  border-radius: 6px;
  border: none;
  color: rgb(75, 85, 99);
  cursor: pointer;
  background: rgb(235, 239, 242);
}

.iconfilter {
  color: #00adc4;
  /* margin-Top: 6px;
    margin-right: 10px; */
  padding: 15px;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0%;
}

.selectmaual {
  border-radius: 0 !important;
  /* border: solid 1px red; */
}

.addbtnacc {
  width: 60%;

  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  cursor: pointer;
}

input[type="file"] {
  /* border: solid 1px red; */
  margin-bottom: 20px;
  /* background: linear-gradient(180deg, #01ACC3, #00535E); */
}

.checksty {
  border-radius: 8px;
  /* accent-color: #00ADC4;   */
  color: #ffff;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.imagewholecontainer {
  /* border: solid 1px red; */
  display: flex;
  height: 40%;
  gap: 10px;
}

.imagedivcon {
  width: 150px;
  height: 150px;
  display: flex;
  border-radius: 5px;
  /* border: solid 1px #2600ff; */
  background-color: #ecf0f3;
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa40;
}

.imagedivinnercon {
  box-shadow: 8px 8px 16px 0px #e0e2e4 inset, -8px -8px 16px 0px #f8f9fa40 inset;
  background-color: #ecf0f3;
  /* border: solid 1px red; */
  /* margin: 10px; */
  border-radius: 5px;
  margin: 4px;
  width: 140px;
  height: 140px;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.bulkuploadinnerbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.custom-file-upload-button {
  background-color: #00adc4;
  /* Desired button color */
  color: #fff;
  /* Text color */
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.custom-file-upload-button:hover {
  background-color: #008a9c;
  /* Darker shade on hover */
}

.custom-file-upload-button:active {
  background-color: #006e7e;
  /* Even darker shade on click */
}

.download-template-button {
  background-color: #00adc4;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.Adddivcon {
  display: flex;
  width: 60%;
}

.addicon {
  color: #00adc4;
  align-self: center;
  margin-right: 5px;
}

.labelcon {
  margin-left: 1px;
  font-family: "poppins" !important;
}

.tablecontent {
  font-family: "poppins" !important;
}

/* student*/
/* .stucheckadd{
    border: soid 1px red;
    width: 300px !important;
    justify-content: end;
} */
/* .ant-checkbox-wrapper {
    width: 300px !important;


} */
.addrescheckbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnaligwitselect {
  /* margin-left: 3rem; */
  /* justify-content: end; */
  display: flex;
  justify-content: center;
  /* gap: 10px; */
  /* width: 400px; */
  /* border: solid 1px red; */
}

.backgrdbtnstufilter {
  width: 10rem;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.iconfilterstu {
  color: #00adc4;
  margin-top: 6px;
  margin-right: 10px;
}

.addbtnaccstu {
  width: 10rem;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.addiconstu {
  color: #00adc4;
  align-self: center;
  margin-right: 10px;
}

.Studentwholeopr {
  padding: 15px;
  width: 99%;
  background-color: #ecf0f3;
  border-radius: 8px;
  overflow-x: hidden;
}
.wholeselect {
  display: flex;
  align-items: center;
}
.wholeselect .ant-select-selector {
  /* border: 1px solid; */
  border-radius: 61px !important;
  width: 166px !important;
  padding: 5px 10px !important;
  /* height: 43px !important; */
  /* width: 166px !important; */
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80;
  background: #ecf0f3;
}
.wholeselect
  .ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #ecf0f3;
}
.wholeselect .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #d1d9e6;
}
.wholeselect
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}

/*Driver*/

.Driver {
  /* margin: 20px; */
  font-family: "Poppins";
  font-weight: 500;
}

.Driverform {
  margin-top: 20px;
  background: #f7f7f7;
  border-radius: 7px;
  box-shadow: 0px 1px 4px 0px #00000040;
  color: #ecf0f3;
}

.driverrowform {
  margin: 0 10px 0 10px !important;
}

.driverinputform {
  background-color: #efefef;
  border: 1px solid #e7e7e7;
  /* border: solid 1px red; */

  border-radius: 5px;
  margin-left: 20px;
}

.inputcon {
  background: #efefef;
  border: 1px solid #e7e7e7;
  border-radius: "5px";
}

.inputcond {
  background: #efefef;
  border: 1px solid #e7e7e7;
  border-radius: "5px";
}

/* //setting */

.addbtnaccsett {
  width: 6rem;
  margin-left: 32rem;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  cursor: pointer;
}

.addbtnaccsettsubject {
  width: 6rem;
  margin-left: 25rem;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  cursor: pointer;
}

.Adddivconsetting {
  /* border: solid 1px red; */
  display: flex;
  width: 100%;
  /* border: solid 1px #77ff00; */
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* margin-Bottom: 10px; */
}

.wholecontainer .ant-list::-webkit-scrollbar {
  width: 6px;
  /* For Chrome, Safari, and Edge: vertical scrollbar width */
  height: 0;
  display: none;
  /* Hides the horizontal scrollbar */
}

.renderuicomp {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 5px;
  font-family: "poppins";
  box-shadow: 0px 4px 4px 0px #00000040;
}

.tablecontent .ant-table {
  border-radius: 12px;
}
.cls-event::after {
  fill: #40ff7c;
}
.tablecontent .ant-table-tbody > tr {
  border-bottom: 1px solid #f0f0f0;
  /* border: solid 1px red; */
}

.ant-menu-submenu-title {
  color: white !important;
  /* Override default color */
  padding-left: 20px;
  font-size: 16px;
}

.ant-menu-submenu-title:hover {
  color: #40a9ff;
  /* Highlight color on hover */
}

/* Submenu item styles */
.ant-menu-item {
  color: lightgray;
  /* Submenu text color */
}

/* Submenu item hover effect */
.ant-menu-item:hover {
  color: white;
  /* Submenu text color on hover */
}

/* Default menu item styles */
.ant-menu-item,
.ant-menu-submenu-title {
  color: white;
  /* Default text color */
}

/* Styles for the selected (clicked) menu item */
.ant-menu-item-selected,
.ant-menu-submenu-title-active {
  background-color: white;
  /* Set background to white */
  font-weight: bold;
  /* Make the text bold */
}

/* Gradient effect for text in active menu items */
.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-submenu-title-active .ant-menu-title-content {
  background: linear-gradient(180deg, #01acc3, #00535e);
  /* Gradient color for text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Gradient for icons in active menu items */
.ant-menu-item-selected .anticon,
.ant-menu-submenu-title-active .ant-menu-title-content .anticon {
  background: linear-gradient(180deg, #01acc3, #00535e);
  /* Gradient color for icon */
  -webkit-background-clip: text;
  color: transparent;
  /* Ensure the default icon color is transparent */
}

.ant-menu-item-selected .ant-menu-item-icon {
  background: linear-gradient(180deg, #01acc3, #00535e);
  background-clip: text;
  color: transparent;
}

.menu-icon {
  font-size: 12px;
  width: 20px !important;
}
.ant-switch {
  background: #ffff;
  border-radius: 20px;
  box-shadow: 4px 4px 8px #bfc6d1, -4px -4px 8px #ffffff;
}
/* .class-data-btn{
  width:30% !important;
} */
.ant-switch:hover {
  background: #ffff;
  border-radius: 20px;
  box-shadow: 4px 4px 8px #bfc6d1, -4px -4px 8px #ffffff;
}

.ant-switch-checked {
  background: linear-gradient(145deg, #007a89, #00a3b1) !important;
}
.ant-switch-checked:hover {
  background: linear-gradient(145deg, #007a89, #00a3b1);
}

.ant-switch .ant-switch-handle::before {
  background: linear-gradient(150.41deg, #00adc4 -24.57%, #00535e 146.24%);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    -2px -2px 5px rgba(255, 255, 255, 0.6);
}

.ant-menu-item-selected .menu-icon {
  background: linear-gradient(
    98.68deg,
    #00adc4 -4.47%,
    #00535e 156.1%
  ) !important;
  color: linear-gradient(98.68deg, #00adc4 -4.47%, #00535e 156.1%) !important ;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.ant-menu-item-selected .menu-icon svg path {
  fill: currentcolor;
}
/* Active main menu item: white background and gradient icon */
.ant-menu-item-selected,
.ant-menu-submenu-title-active {
  background-color: white;
  /* White background for active menu */
  font-weight: bold;
  /* Optional: Bold text */
}

/* Gradient effect for icons in main menu */
.ant-menu-item-selected .anticon,
.ant-menu-submenu-title-active .ant-menu-title-content .anticon {
  background: linear-gradient(180deg, #01acc3, #00535e);
  /* Gradient for icon */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Make icon gradient visible */
  color: transparent;
  /* Override default color */
}

/* Active submenu: white background and gradient icon */
.ant-menu-sub.ant-menu-inline > .ant-menu-item-selected,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu-title-active {
  background-color: white;
  /* White background for active submenu */
}

/* Sidebar styling */
.sidebar {
  width: 850px;
  padding: 15px;
  background: linear-gradient(180.05deg, #00adc4 0.04%, #00535e 283.29%);
  overflow-y: auto;
}

/* Styling for individual menu items */
.menu-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  margin-bottom: 15px;
}
.ant-menu .ant-menu-item {
  border-radius: 6px;
}
.class-search-sec {
  p {
    margin: 0px 0px 0px 5px !important;
    font-size: 14px;
    font-weight: 500;
  }
}
.ant-picker-time-panel {
  height: 100px !important;
}
.ant-pagination {
  position: unset !important;
}
.ant-menu {
  background: linear-gradient(
    180.05deg,
    #00adc4 0.04%,
    #00535e 283.29%
  ) !important;
}
.ant-menu-item-active {
  color: #8787d5;
  /* border: solid 1px #55ff00; */
}
.ant-btn.ant-btn-icon-only .anticon {
  font-size: 13px !important;
}
@media (max-width: 955px) {
  .nav-sectionBar {
    display: flex !important;
    flex-direction: column !important;
  }
}
.outletcontainer {
  /* border: solid 1px rgb(223, 150, 40); */
  margin: 0px 10px 10px 10px;
  border-radius: 10px;
  /* box-shadow: 18px 18px 30px 0px #D1D9E6, -18px -18px 30px 0px #FFFFFF; */

  /* overflow-y: auto; */
}

.outletinnercontainer {
  padding: 16px 27px;
  width: 100%;
  height: auto;
  background-color: #ecf0f3;
  border-radius: 10px;
  /* box-shadow: 18px 18px 30px 0px #D1D9E6, -18px -18px 30px 0px #FFFFFF; */
}

/* General Sidebar Styles */
.ant-layout-sider {
  transition: all 0.3s ease;
  background: linear-gradient(180.05deg, #00adc4 0.04%, #00535e 283.29%);
}

.ant-menu-item {
  font-size: 16px;
  color: #fff !important;
  display: flex;
  align-items: center;
  gap: 10px;
  /* border: solid 1px red; */
}
.ant-select-selection-placeholder {
  color: #000 !important;
}
/* .ant-select .ant-select-arrow {
  top: 60% !important;
}
.ant-select .ant-select-clear {
  top: 60% !important;
} */
/* Center align icon in collapsed mode */
.ant-menu-item-only-child {
  justify-content: center;
}

/* Hide labels in collapsed mode */
.ant-menu-item span {
  display: inline-block;
  justify-content: center;
}

.ant-menu-inline-collapsed span {
  display: none;
}

/* Adjust icon size */
.ant-menu-item .anticon {
  font-size: 20px;
  color: #fff !important;
}

/* Sidebar Styling */
.sidebar {
  background-color: #00a5b8;
  /* Sidebar color */
  width: 250px;
  /* Adjust based on your layout */
  height: 100vh;
  /* Full height */
  border-top-right-radius: 20px;
  /* Top-right curve */
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  position: fixed;
  /* Fixed positioning */
  top: 0;
  left: 0;
}
/* .data-page-index {
  position: "absolute";
  margin-top: 30px;
  padding: 2px;
  border-radius: 5px;
  display: "flex";
  width: 100%;
  justify-content: "space-between";
} */
/* Navbar Styling */
.navbar {
  background-color: #f5f7fa;
  height: 70px;
  margin-left: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 20px;
  position: fixed;
  width: calc(100% - 250px);
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.class-card-data {
}
/* Content Area */
.content {
  margin-top: 70px;
  margin-left: 250px;
  padding: 20px;
  background-color: #fff;
  height: calc(100vh - 70px);
}

.ant-menu-light .ant-menu-item-selected::after {
  /* background-color: #00adc4; */
}
.ant-menu .ant-menu-item .ant-menu-item-icon svg {
  /* fill:linear-gradient(180deg, #00adc4 0%, #00535e 100%); */
  /* -webkit-text-fill-color: transparent !important; */
  /* color: #00adc4; */
  color: linear-gradient(180deg, #00adc4 0%, #00535e 100%);
}

@media (max-width: 1960px) {
}

@media (min-width: 768px) {
  /* .tableChart-1{
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  /* .linecharts{
    width:220px;
  } */
}

@media (max-width: 768px) {
  .logotypo {
    display: none;
  }

  .tableChart-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
  }
}

@media (max-width: 575px) {
  .class-data {
    height: 142px !important;
  }
  /* .Accodamicwholeopr {
    margin: 50px 0 0px 25px !important;
  } */
  .circuarwholeopr {
    margin: 30px 0 0px 20px !important;
  }

  .upload {
    margin-left: 25px !important;
  }
}
.class-view-btn .ant-tabs-tab-btn {
  color: #000 !important;
}
/* .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #00adc4 !important;
} */
.ant-picker-content {
  background-color: #ffffff;
  /* border: solid 1px; */
  width: 100% !important;
  height: 5px !important;
  box-sizing: border-box;
}

/* Default styles */
.selectorclass {
  width: 100%;
  max-width: 400px;
  background: #efefef;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}

/* Medium screens (tablets) */
@media (min-width: 768px) {
  .selectorclass {
    width: 80%;
  }
}

/* Large screens (desktops) */
@media (min-width: 1200px) {
  .selectorclass {
    width: 70%;
    /* Further adjustment for larger screens */
  }
}

/* Extra-large screens (widescreens) */
@media (min-width: 1700px) {
  .selectorclass {
    width: 60% !important;
    /* Specific width for extra-large screens */
  }
}

.tableinnercontainer {
  width: 100%;
  position: relative;
}

.btnacccon {
  /* For debugging */
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  /* align-items: center; */
  /* border: solid 1px red; */
  background-color: #ecf0f3 !important;
}
.class-data {
  height: 242px !important;
}

.filterbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* width: 60%; */
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto 10px;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  /* border: solid 1px blue; */
}
/* .pagesofpage {
  display: flex !important;
}
.paginations {
  display: flex !important;
}
@media (min-width: 1150px) {
  .paginations {
    display: none !important;
  }
} */
.addbtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* gap: 8px; */
  width: 166px;
  height: 40px;
  border-radius: 5px;
  background-color: #ecf0f3;
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
  /* margin: 0 auto 10px; */
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
}

.icon {
  color: #00adc4;
}

.selectwithbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: solid 1px red; */
}

.slectwithbtninner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.selectdivstudent {
  /* width: 100%; */
  border: none;
  margin-left: 10px;
  /* border: solid 1px #00ff84; */
}

.filterbtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* width: 10%; */
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto 10px;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
}

.chart-labels {
  margin-top: 2rem;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  /* border: solid 1px; */
}

.chart-labelss {
  justify-content: space-between;
  display: flex;
  font-size: 10px;
  flex-wrap: wrap;
  color: #9b9b9b;
  /* border: solid 1px; */
  padding: 10px;
}

.chart-labels .label {
  display: flex;
  align-items: center;
  /* margin: 0.5rem 1rem; */
}

.chart-labelsforteacher {
  justify-content: space-between;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 18rem;
}

.labelpresent .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.labelabsent .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.studentattenanceheader {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.chart-labelsforstudent {
  justify-content: space-between;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
  padding: 10px;
}

.chart-labels .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}
.classdatafca {
  height: 242px !important;
  padding: 5px 0px !important;
}
.classdatafca.ant-card .ant-card-body {
  padding: 0 !important;
}
.class-select-btn .ant-select-selector {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 40px !important;
  padding: 5px 10px !important;
  background-color: #fff;
  border: none !important;
  width: 100% !important;
  box-shadow: 0px 4px 4px 0px #dadada;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.ant-picker-calendar-mini .ant-picker-panel {
  width: 100%;
}
/* .class-select-btn .ant-select-arrow {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */
/* .ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: -1 !important;
} */
.class-select-btn .ant-select-selection-placeholder {
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.ant-card .ant-card-body {
  padding: 14px 24px 24px 24px !important;
}
.custom-calendar {
  font-family: "poppins";
}

.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #00a5b8;
  border-radius: 50px;
  box-shadow: 0px 1px 4px 0px #00000040;
}

.chart-labelss .label {
  display: flex;
  align-items: center;
  margin: 0.5rem 1rem;
}
.class-select-btns .ant-select-selector {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  /* border-radius: 40px !important; */
  padding: 5px 10px !important;
  background-color: #ecf0f3 !important;
  border: none !important;
  width: 100% !important;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80 !important;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.class-select-btns .ant-select-selection-placeholder {
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.chart-labelss .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.Drivers-History-btn button {
  background-color: #ffffff;
  color: #4b4a4a;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  width: 148px;
  border: none;
  height: 33px;
  box-shadow: 0px 4px 4px 0px #dadada;
}
.ant-popover-inner {
  height: auto;
}
.modelpopover {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.modelpopover .manualAdd:hover {
}
.modelpopover .manualAdd {
  padding: 5px;
  font-size: 12px;
  /* font-weight: 600; */
  font-family: "poppins";
  background: linear-gradient(180deg, #00adc4 0%, #00535e 100%);
}
.modelpopover .bulkAdd {
  padding: 5px;
  font-size: 12px;

  font-family: "poppins";
  background: linear-gradient(180deg, #00adc4 0%, #00535e 100%);

  box-shadow: 18px 18px 30px 0px #d1d9e6;
}
.certificate-links {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}

.manualAdd,
.bulkAdd {
  padding: 10px;
  background: linear-gradient(180.05deg, #00adc4 0.04%, #00535e 283.29%);
  border: none;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
}

.manualAdd {
  margin-bottom: 20px;
}

.table-sort-arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  margin-left: 3px;

  i {
    font-size: 9px;
    line-height: 8px;
  }
}

.inputbox .ant-picker-outlined {
  border: none !important;
}

@media screen and (max-width: 480px) {
  .linecharts {
    /* border: solid 1px red; */
    /* height: 50px !important; */
    margin-top: 100px;
    justify-content: stretch;
    width: 100% !important;
  }

  .ant-layout-sider {
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
  }
}

.toppagination {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  /* width: 300px; */
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80;
  box-sizing: border-box;
}

.toppagination .page-link {
  font-family: "Poppins" !important;
  background-color: #ecf0f3;
  /* width: 60px; */
  color: #6c757d;
  font-size: 10px;
  padding: 10px;
  border: solid 1px #ecf0f3;
  border-radius: 5px;
  text-align: center;
  margin-top: 15px;
}

.toppagination .page-link:hover,
.toppagination .page-link:active,
.toppagination .page-link:focus {
  background-color: #01acc3;
  color: #fff;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none !important;
}

.toppagination .page-link:focus {
  background-color: #00adc4;
}

.pagination .page-item .page-link {
  padding: 4px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.pagination .page-item.active .page-link {
  background-color: #17a2b8;
  color: white;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
}

.pagination .page-item .page-link:hover {
  background-color: #138496;
  color: white;
}
.custom-save-button {
  background: linear-gradient(101.89deg, #00adc4 -9.22%, #00535e 121.14%);
  border: none;
  border-radius: 2px;
}
.custom-save-button:active {
  background: linear-gradient(
    101.89deg,
    #00adc4 -9.22%,
    #00535e 121.14%
  ) !important;
  border: none !important;
  border-radius: 2px;
}
.custom-save-button {
  background: linear-gradient(
    101.89deg,
    #00adc4 -9.22%,
    #00535e 121.14%
  ) !important;
  border: none !important;
  border-radius: 2px;
}
.custom-cancel-button {
  border: 1px solid #ec7c74;
  background: #ec7c74;
  color: #ffff;
  border-radius: 2px;
}
.custom-cancel-button:hover {
  border: 1px solid #ec7c74;
  background: #ec7c74 !important;
  color: #ffff !important;
  border-radius: 2px;
}
.custom-cancel-button:active {
  border: 1px solid #ec7c74 !important;
  background: #ec7c74 !important;
  color: #ffff !important;
  border-radius: 2px;
}
.calendar-container {
  background: #ecf0f3;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
}

.calendar-header button {
  background-color: #00adc4;
  color: #fff;
  border: none;
}

.calendar-header button:hover {
  background-color: #0c6a6e;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.calendar-cell {
  height: 80px;
  background: #ecf0f3;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;
  box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa;
}
.department_select .ant-select-arrow {
  display: none !important;
}
.events-container {
  background: #ecf0f3;
  border-radius: 6px;
  /* border: solid 1px red !important; */
  height: 92vh;
  overflow: auto;
  box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa;
}
.events-container::-webkit-scrollbar {
  display: none;
}
h5 {
  font-family: "poppins";
}
.event-item {
  background: #ecf0f3;
  justify-content: space-between;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  /* margin-bottom: 25px; */
  font-family: "poppins";
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
}
.eventitemcontainer {
  /* border: solid 1px red; */
}
.event-item p {
  margin: 0 !important;
}
.addeventbtn {
  background: linear-gradient(94.86deg, #00adc4 -5.38%, #00535e 123.05%);
  font-family: "poppins";
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  padding: 8px;
}

.eventheader {
  background: #ecf0f3;
  color: #000000;
  width: 100%;
  /* top: 10%; */

  position: sticky;
  top: 0;
  /* border: solid 1px red; */
  display: flex;
  text-align: center;
}
.tabselection {
  /* border: solid 1px; */
  display: flex;
  width: 500px !important;
}
.tabselection .tabselect {
  padding: 10px;
  /* border: 1px solid; */
  height: auto;
  justify-content: center;
  align-items: center;
}
.tabselection .tabselect.active {
  background: linear-gradient(98.22deg, #00adc4 -13.56%, #00535e 133.98%);
}
.btndepadmin {
  /* border: solid 1px red; */
  margin-left: 20px;
}
.btndepadmin .btndepadminstu {
  background: #00adc4;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100px;
  padding: 5px;
}
.btndepadmin .btndepadminass {
  background: #525252;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-left: 5px;
  width: 100px;
  padding: 5px;
}

.classinfotab {
  display: flex;
  /* width: 400px;
  height: 54px; */
  align-items: center;
  /* margin-bottom: 30px;
  padding: 0; */
}
.classinfotab .classbtn {
  border: none;
  padding: 0px 20px;
  margin: 0;
  /* width: 200px; */
}

.classinfotab .ant-tabs-tab-active {
  background: linear-gradient(98.22deg, #00adc4 -13.56%, #00535e 133.98%);
  color: #ffff !important;
}
.selctclsinfo {
  top: 272px;
  left: 856px;
  gap: 19px;
  opacity: 0px;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80;
  background: #ecf0f3;
}
.selctclsinfo .ant-select-selector {
  width: 166px;
  height: 43px;
  background: #ecf0f3;
}
.selctclsinfo .ant-select-selector:active {
  outline: none;
}
.selctclsinfo .ant-select-selector:focus {
  outline: none;
}
.selctclsinfo .ant-select-outlined:not().ant-select-selector {
  border: 1px solid #d9d9d9;
  background: #1177c5;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff80;
}
.selctclsinfo .ant-select-selection-placeholder,
.selctclsinfo .ant-select-arrow {
  color: #000000;
}
.selctclsinfo .ant-select-selection-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clasmodelpopup {
  color: #00adc4;
  text-align: center;
  font-family: "poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.ant-spin-container::-webkit-scrollbar {
  display: none !important;
}
.feesinner {
  /* border: solid 1px red; */
  margin: 30px 10px 0 10px;
  /* padding: 5px; */
  background: #ecf0f3;
  box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa;
}
.inputboxff {
  background-color: #e9e9e9;
  border: 1px solid #e1e1e1;
  outline: none !important;
  border-radius: 3px;
}

.feescontainer {
  /* border: solid 1px red; */
}
.feesinnercontainer {
  background: #ecf0f3;

  padding: 30px;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff;
}
.feesbtn {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 30px;
}
.tablehearder {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px;
  /* text-align: center; */
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.feesbtn .feessavebtn {
  border: 0.5px solid #00535e;
  color: #000000;
  padding: 8px;
  border-radius: 5px;
  font-family: "Poppins";
  font-weight: 500;
  background: #fff;
}
.feesbtn .feespublishbtn {
  background: linear-gradient(180deg, #00adc4 0%, #00535e 100%) !important ;
  border: none;
  color: #fff;

  border-radius: 5px;
  font-family: "Poppins";
  font-weight: 500;
  /* border-color: linear-gradient(180deg, #00adc4 0%, #00535e 100%) !important; */
}
.addfeesbtncon {
  position: absolute;
  top: 130%;
  left: 50%;
  z-index: 400;
}
.addfeesbtncon .addbtncon {
  border: none;
  background-color: #00adc4;
  padding: 15px;
  color: #fff;
  border-radius: 5px;
  /* height: 30px; */
}
.declasshearder {
  /* border: solid 1px; */
  display: flex;
}
.selectheaderclass {
  display: flex;
}

.emtytable {
  /* border: solid 1px red; */
  width: 100%;
  /* background-color: #00adc4; */
  height: 50px !important;
  color: #fff;
  font-family: "poppins";
  font-weight: 500;
  /* text-align: center; */
}
.emtytable table {
  width: 100%;
  border-collapse: collapse;
  font-family: "poppins" !important;
  font-weight: 500;
  font-size: 14px;
}

.emtytable thead {
  background-color: #00adc4;
  color: #fff;
}

.theadhover:hover {
  background-color: #00adc4 !important;
  color: #fff;
}
.addfesbtn {
  padding: 10px;
  border: none;
  background-color: #00adc4;
  border-radius: 4px;
  color: #fff;
  font-family: "poppins";
  font-size: 16px;
  font-weight: 500;
}

th,
td {
  padding: 12px 15px;
  /* text-align: left; */
}

/* tr:nth-child(even) {
tr:nth-child(even) {
  background-color: #f2f2f2;
} */

.theadhover tr:hover {
  background-color: #00adc4;
}

td {
  color: #555;
}
.table-container {
  max-width: 800px;
  margin: 20px auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportcontainer {
  width: 100%;
  height: auto;
  padding: 20px;
}
.reportcontainer .ant-layout {
  width: 100% !important;
}
.reports-container {
  background: #ecf0f3;
  /* display: flex; */
  /* flex-wrap: wrap;
  gap: 20px; */
  /* border-radius: 2px; */
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff;
  /* padding: 50px; */
  justify-content: start;
}

.report-card {
  flex: 1 1 calc(33.333% - 20px);
  /* max-width: calc(33.333% - 20px); */
  background: #ecf0f3;
  border-radius: 10px;
  /* border-radius: 8px; */
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
  padding: 8px;
  text-align: center;
}

.image-section img {
  width: fit-content;
  height: auto;
  /* border-radius: 8px; */
}

.content-section {
  display: flex;
  justify-content: start;
  align-items: center;
}

.text-container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.text-container .title {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px;
  text-align: center;
}

.text-container .date {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: end;
  text-decoration-skip-ink: none;
  color: #666;
}

.button-section {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 5px;
}

.btn-view,
.btn-export {
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
}

.btn-view {
  background-color: white;
  color: black;
  width: 90px;
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
}

.btn-export {
  background-color: #00adc4;
  width: 90px;
  border: none;
  color: #fff;
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
}
.classinfocontainer {
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 60px;
  margin-bottom: 15px;
}
.custom-breadcrumb .ant-breadcrumb-separator,
.ant-breadcrumb-link {
  color: #00adc4;
  font-family: "poppins";
  font-size: 14px;
  font-weight: 500;
}
.custom-breadcrumb a {
  font-family: "poppins";
  text-decoration: none;
}

.timetable-container {
  max-width: 900px;
  margin: auto;
  background-color: #ecf0f3;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.timetable-container .row > .col:hover {
  background-color: #ecf0f3;
  cursor: pointer;
}

/* TEACHER LOGIN */
.class-user_name {
  /* display: flex;
  align-items: center; */
  color: #fff;
  background-color: #00adc4;
  border: 1px solid #dedede;
  box-shadow: 0px 4px 4px 0px #00000040;
  p1 {
    font-weight: 600;
    font-size: 24px;
  }
  p {
    font-weight: 500;
    font-size: 15px;
  }
}
.class-punchin-time {
  background-color: #f7f7f7;
}
.class-punchin-event {
  background-color: transparent !important;
}
.class-punchin-time {
  align-items: center;
  justify-content: center;
}
.class-punchIn {
  margin-top: 75px;
  margin-bottom: 65px;
  margin-inline: auto;
  border-radius: 50%;
  padding: 35% 20%;
  border: none;
  color: #00adc4;
  background-color: #f7f7f7 !important;
  box-shadow: 18px 18px 30px 0px #efefef, -18px -18px 30px 0px #ffffff;
}
.punchin-time-1 {
  padding-top: 320px !important;
  p {
    margin: 0px !important;
  }
}
.punchin-time-2 {
  padding-top: 95px !important;
  p {
    margin: 0px !important;
  }
}
.class-punchInTime {
  button {
    border: none !important;
    border-radius: 28px;
    color: #fff;
    width: 137px;
    height: 38px;
  }
  .Btn-1 {
    background-color: #00adc4;
  }
  .Btn-2 {
    background-color: #ec7c74;
  }
}
/* .class-timeTable .ant-picker-panel {
  display: none !important;
} */
.timetable-Calendar .ant-picker-panel {
  display: none !important;
}
.calender-date {
  width: 238px !important;
  gap: 35px;
}
.class-all_datas {
  p {
    font-size: 16px;
    font-weight: 600;
  }
}
.ring-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}
.ring-container.ant-layout {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.classroom-charts .col-md-6 {
  border-radius: 5px;
  background-color: #ecf0f3;
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
}
.std-info-submit_btn {
  display: flex;
  justify-content: center;
}
.std-info-submit_btn input {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #00adc4;
  border: none;
  border-radius: 5px;
  padding: 12px 22px;
  width: fit-content;
}
/* .classroom-charts.ant-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
} */

/* .table-class_datas {
  height: 400px !important;
} */
.class-TimeOver {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: fit-content;
  padding: 5px;
  background-color: #ec7c74;
  p1 {
    text-decoration: line-through;
    white-space: nowrap;
  }
}
.class-TimeIn {
  display: flex;
  width: fit-content;
  flex-direction: column;
  color: #fff;

  padding: 5px;
  background-color: #00adc4;
  p1 {
    white-space: nowrap;
  }
}
.class-timeOfClass {
  overflow: auto;

  display: flex;
  gap: 10px;
}
.class-timeTable .ant-picker-panel {
  display: none;
}
.ant-pagination .ant-pagination-options {
  display: flex !important;
  /* position: absolute !important; */
  right: 10;
}
.pagination_align {
  position: relative;
}
/* .class-timetables {
  display: flex;
  flex-direction: column;
} */
.ant-spin-nested-loading {
  overflow: scroll !important;
  scrollbar-width: none !important;
}
.clas-student_Title {
  background-color: #00adc4;
  padding: 38px 24px;
  border-radius: 11px;
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
}
/* .class-student_data {
  line-height: 27px;
} */
.class-data_img {
  border: 5px solid #ecf0f3;
  border-radius: 50%;
  width: fit-content;
  img {
    width: 176px;
    height: 176px;
    border-radius: 50%;
  }
}
.clas-data_details {
  p1 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
  }
  p2 {
    color: #e6e6e6;
    font-size: 20px;
    font-weight: 500;
  }
  p3 {
    color: #e6e6e6;
    font-size: 13px;
    font-weight: 500;
  }
}
.class-student_fullDetails button {
  border: none;
  padding: 10px 14px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 7px;
  color: #00adc4;
}
.class-punchIns {
  margin-top: 75px;
  margin-bottom: 65px;
  margin-inline: 40px;
  border-radius: 50%;
  padding: 20% 10%;
  border: none;
  color: #00adc4;
  background-color: #f7f7f7 !important;
  box-shadow: 18px 18px 30px 0px #efefef, -18px -18px 30px 0px #ffffff;
}
.recharts-cartesian-grid {
  display: none !important;
}
.class-classroom_details {
  .ant-card {
    background-color: #ecf0f3 !important;
    box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
  }
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.class-view-btn.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #008c8c !important;
}

/* SuberAdmin style */
.container-fluid {
  padding: 25px !important;
}

.dashboard-data {
  /* background-image: linear-gradient(45deg, #eb9e58, #dab798); */
  border-radius: 8px !important;
  background-color: #ecf0f3;
  box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa;
  padding: 25px;
  p1 {
    font-weight: 600;
    font-size: 40px;
    color: #ffffff;
  }
  p2 {
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
  }
}
.dashboard-data.bg-1 {
  background-image: linear-gradient(45deg, #eb9e58, #dab798);
}
.dashboard-data.bg-2 {
  background-image: linear-gradient(45deg, #8258be, #c1a0ee);
}
.dashboard-data.bg-3 {
  background-image: linear-gradient(45deg, #4b7478, #8ad7de);
}
/* .dashboard-data {
  background-image: linear-gradient(45deg, #8258be, #c1a0ee);
  border-radius: 8px !important;
  background-color: #ecf0f3;
  box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa;
}
.dashboard-data {
  background-image: linear-gradient(45deg, #4b7478, #8ad7de);
  border-radius: 8px !important;
  background-color: #ecf0f3;
  box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa;
} */
.dashboard-data-img {
  display: flex;
  justify-content: flex-end;
  img {
    background-color: #ffffff;
    width: fit-content;
    padding: 7px;
    border-radius: 50%;
  }
}
.dashboard-data-chart {
  background-color: #ecf0f3;
  box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa;
  border-radius: 8px;
  height: 100%;
}
.dashboard-table-btn {
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.table_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  /* width: 242px; */
  background-color: #ecf0f3;
  box-shadow: 4px 4px 8px 0px #e0e2e4 inset, -4px -4px 8px 0px #e0e2e4 inset;
  /* box-shadow: 4px 4px 8px 0px #e0e2e4, -4px -4px 8px 0px #f8f9fa; */
  filter: drop-shadow(4px 4px 8px #e0e2e4) drop-shadow(-4px -4px 8px #f8f9fa);
  /* width: fit-content; */
  padding: 5px 15px;
  button {
    border: none;
    background-color: #ecf0f3;
  }
  input {
    background-color: transparent;
    border: none;
    mask-border-outset: none;
  }

  input:hover {
    background-color: transparent;
  }
}
.dashboart-data--table .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #ffffff;
  color: #000;
}
.dashboart-data--table .ant-table-wrapper .ant-table-thead > tr > th:hover {
  background: #ffffff !important;
}
.dashboard-table-btn .table-width-1 {
  width: 316px;
}
.dashboard-table-btn .table-width-2 {
  width: 221px;
}
.dashboard-table-btn .table-width-3 {
  width: 242px;
}
.table_btn .custom-select .ant-select-selector {
  background-color: transparent !important;
  border: 1px solid #ccc; /* Optional: add border */
}
.chart_table {
  height: 551px;
}
.pie-chart-container {
  width: 100%;
  height: auto;
  min-height: 300px; /* Adjust as needed */
}
.Navbar_header {
  padding: 50px 50px;
}
@media (max-width: 768px) {
  .pie-chart-container {
    min-height: 250px;
  }
}

@media (max-width: 480px) {
  .pie-chart-container {
    min-height: 200px;
  }
  .Navbar_header {
    padding: 50px 10px !important;
  }
}
/* Super-Admin-loginProfile */
.Login-info-data {
  background-color: #ecf0f3;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
}
.Login-info-id {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  background-color: #ecf0f3;
  border-radius: 30px;
  box-shadow: 8px 8px 16px 0px #e0e2e4, -8px -8px 16px 0px #f8f9fa;
  padding: 25px 50px;
  margin-bottom: 20px;
  img {
    display: flex;
    align-items: center;
    width: 192px;
    height: 192px;
    border-radius: 50%;
    background-color: #ecf0f3;
    box-shadow: 4px 4px 8px 0px #e0e2e4 inset, -4px -4px 8px 0px #f8f9fa inset;
  }
  h2 {
    color: #00adc4;
    font-size: 40px;
    font-weight: 600;
  }
  p {
    color: #8e8c8c;
    font-size: 16px;
    font-weight: 500;
  }
  p2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    /* margin-bottom: 10px; */
  }
  p1 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
}
.Login-pass-data {
  background-color: #d9d9d9;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.Login-info-pass {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 20px;
}
.Login-info-infor .ant-row {
  flex-direction: column !important;
  align-items: flex-start !important;
}
.Login-info-infor .ant-form-item .ant-form-item-control {
  width: 100% !important;
}
.Login-info-infor button {
  padding: 4px 20px;
  border: none;
  background-color: #ffffff;
}

.sroll-wrapper {
  /* width: calc(100% - 250px); */
  overflow: scroll hidden;
}
