.ant-card {
  background: #f7f7f7;
  border: none;
  height: 100% !important;
}

/* .ant-card-body {
  padding: 24px;
} */

.ant-input[disabled] {
  color: #4a4a4a;
  background-color: #f5f5f5;
}

/* .ant-btn {
  height: 40px;
} */

.ant-tabs-tab {
  font-weight: 500;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
}

.ant-tag {
  font-size: 14px;
  padding: 4px 12px;
}

.rejectbtn {
  background: linear-gradient(
    97.16deg,
    #c15c55 -51.63%,
    #7c221c 141.34%
  ) !important;
  border: none !important;
  color: #ffffff !important;
}

/* Scrollbar container */
.scrollable-container {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
}

/* Customize the scrollbar */
.scrollable-container::-webkit-scrollbar {
  width: 1px !important;
  /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Track color */
  border-radius: 58px !important;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #00adc4;
  /* Thumb color */
  border-radius: 18px;
  border: 2px solid #f0f0f0;
  /* Space around the thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #007b9e;
  /* Darker thumb color on hover */
}

/* For Firefox */
.scrollable-container {
  scrollbar-width: none;
  /* Makes the scrollbar thinner */
  scrollbar-color: #00adc4 #f0f0f0;
  /* Thumb and track colors */
}

.popupmsg {
  font-size: 18px;
  font-family: "poppins";
  font-weight: 600;
  justify-content: center;
  text-align: center;
}

.popupsuccmsg {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #00adc4;
}
.appbtn {
  background: linear-gradient(
    94.83deg,
    #00adc4 -5.79%,
    #00535e 140.9%
  ) !important;
  color: #ffffff;
  gap: 0px;
  border-radius: 6px;
  opacity: 0px;
  margin-right: 20px;
}
.cancelbtn {
  background-color: #00535e;
  color: #ffffff;
  margin-right: 40% !important;
  border-radius: 5px;
  height: 25px;
}
