/* TeacherAttendanceChart.css
.chart-container {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    
  }
  
  .doughnut-chart {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  
  .chart-center-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .chart-center-icon img {
    width: 40px;
    height: 40px;
  }
  
  .chart-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .label .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .label .percentage {
    font-weight: bold;
  }
  
  .present .percentage {
    color: #3ea7a7;
  }
  
  .absent .percentage {
    color: #f47373;
  }
  .Teachericonoutercomponent{
    /* box-shadow: -18px -18px 30px 0px #FFF inset, 18px 18px 30px 0px #D1D9E6 inset; */
/* border: solid 1px #8a2be2; */
/* border-radius: 50%;
    height: 150px;
    width: 150px;
    margin: 110px 0 0 0px;

    position: relative;
    /* border:solid 1px red; */
/* background-color: #FFF !important; 
    } 
  .Teachericoninnercomponent{
    filter:drop-shadow(18px 18px 30px #D1D9E6) drop-shadow(-18px -18px 30px #FFF);
    margin: 10px 0 0 10px;
    position: absolute;
    border-radius: 50% !important;
   
  }
  .teacherchartlabels{
    border: solid 1px red;
    margin-top: 100px;
  }

  .Teachericoninnercomponent {
    width: 100%;
    max-width: 8rem;
    background-color: #ecf0f3;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.09);
  }
  
  .chart-labels {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .chart-labels .label {
    display: flex;
    align-items: center;
    margin: 0.5rem 1rem;
  }
  
  .chart-labels .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  } */
.Teachericoninnercomponent {
  width: 100%;
  /* max-width: 8rem; */
  background-color: #ecf0f3;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.09);
}



@media (max-width: 1960px) {
  .aligncharts {
    /* border: solid 1px red; */
    margin-top: 1%;
    margin-right: 20% !important;
  }

  .Teachericonoutercomponent {
    /* border: solid 1px red; */

  }
}

@media (max-width: 1240px) {

    .chart-labels{
      /* margin-top: 260px; */
      /* border: solid 1px red; */
    
  }

  .Teachericoninnercomponent {
    /* border: solid 1px red;  */
    border-radius: 50%;

  }

}

@media (min-width :1200px) {
  .chart-labels {
    flex-direction: row;
    align-items: center;
  }

  .aligncharts {
    top: 75%;
    left: 50%
  }

  .Teachericonoutercomponent {
    /* margin-top: 90%; */
    /* margin-left: 10px;   */
    width: 8rem !important;
    height: 8rem;
    border-radius: 50%;
    /* margin-right: 60% !important; */
    /* border: solid 1px red; */

  }

  .Teachericoninnercomponent {
    /* border: solid 1px red;  */
    border-radius: 50%;
    margin-top: 5%;
    width: 7rem !important;
    height: 7rem;
    /* margin-left: 1.5rem; */
    box-shadow: 18px 18px 23.3px 0px #D1D9E680, -18px -18px 26px 0px #FFFFFF80;

    margin-left: 6px;
  }
}


@media (max-width: 768px) {
  .chart-labels {
    flex-direction: row;
    align-items: center;
  }
  

  /* .chart-labels{
    margin-top: 250px;
  } */

  .Teachericonoutercomponent {
    margin: 0 auto;

  }
}
/* :where(.css-dev-only-do-not-override-apn68).ant-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
}
:where(.css-dev-only-do-not-override-apn68).ant-input-outlined{
  width:min-content !important;
} */
@media (max-width: 480px) {
  .chart-labels .label {
    justify-content: center;
    margin: 0.5rem 0;
  }
  /* .chart-labels{
    margin-top: 250px;
  } */


  h2 {
    font-size: 12px;
  }

  .Teachericoninnercomponent {
    max-width: 6rem;
  }
}
.chartatt{
  height: 100%;
}
.chartatt .ant-card-body{
  height: 100%;
}