/* .ant-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

/* .ant-card-body {
  height: 450px;
} */

/* .ant-btn {
  width: 100%;
} */

/* .ant-btn-primary {
  background-color: #00adc4;
  border: solid 1px red;
  border-color: #00adc4;
} */

.cardtype {
  font-family: 'poppins';
  font-weight: 600;
  font-style: normal;
  margin-top: 30px;
}

:where(.css-dev-only-do-not-override-apn68).ant-card-bordered {
  /* filter: drop-shadow(18px 18px 30px #D1D9E6) drop-shadow(-18px -18px 30px #FFF); */
}

.courseparadesc {
  /* font-size: 14px;
   
    margin-Bottom: 16px; */
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #A0A0A0;
  text-decoration-skip-ink: none;

}
.statusdiv{
  border: solid 1px #00ADC4;
  /* padding: 4px; */
  border-radius: 15px;
 justify-self: center !important; 
  /* /*  align-self: center !important; */
  /* margin-left:5rem; */
  color: #00ADC4;
}
.viewbtncourse {
  background: linear-gradient(98.68deg, #00ADC4 -4.47%, #00535E 156.1%) !important;
  border: none;

}

.cancelbtncourse {
  border: 1px solid #E2E2E2;
  background-color: #E9E9E9;
  margin-left: 5px;
}

.card-slider {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.card-slider::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

/* @media (max-width: 1024px) {
  .card-slider > div {
    flex: 1 1 calc(50% - 16px); */
     /* Two cards per row for tablets */
  /* }
} */


