/* .tabviewsubcon{
    border: solid 1px red;
   
    background: #ECF0F3;
    justify-content: space-evenly;
    box-shadow: 18px 18px 30px 0px #D1D9E6;



} */

.Tabcontainer {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.Tabcontainer .ant-tabs-nav {
  display: flex;
  justify-content: space-between;
  background-color: #ecf0f3; /* Light background */
  border-bottom: none; /* Remove border */
  text-decoration: none !important;
  height: 40px;
  /* width: 900px; */
  /* border: solid 1px #b22020; */
  /* margin-left: 10px; */
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff; /* Combine both shadows */
  border-radius: 8px; /* Rounded corners for the tab bar */
  /* border: solid 1px red; */
  margin-top: 20px;
}

.Tabcontainer .ant-tabs-tab {
  /* font-weight: bold; */
  color: #000; /* Default text color */
  padding: 10px 20px;
  text-decoration: none !important;
  font-family: "poppins", sans-serif;
}

.Tabcontainer .ant-tabs-tab-active {
  color: #fff !important; /* Active tab text color */
  background-color: #008c8c; /* Active tab background color */
  border-radius: 8px; /* Rounded corners for active tab */
  border-bottom: none !important;
}

.Tabcontainer .ant-tabs-tab:hover {
  color: #008c8c; /* Hover state text color */
}

.Tabcontainer .ant-tabs-nav .ant-tabs-tab {
  transition: background-color 0.3s, color 0.3s;
}

.Tabcontainer .ant-tabs-tab-bar {
  margin-bottom: 20px;
}

.Tabcontainer .ant-tabs-nav {
  display: flex;
  justify-content: space-between;
  background-color: #ecf0f3; /* Light background */
  border-bottom: none; /* Remove border */
  /* border: solid 1px red; */
  text-decoration: none !important;
  height: 55px;
  /* width: 90%; */
  /* margin-left: 20px; */
  /* margin-top: 10px; */
  padding: 7px 25px;
  font-family: "poppins";
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
}

.Tabcontainer .ant-tabs-tab {
  color: #000; /* Default color */
  height: 2rem;
  text-decoration: none !important;
}

.Tabcontainer .ant-tabs-tab-active {
  background: linear-gradient(98.68deg, #00adc4 -4.47%, #00535e 156.1%);
  border-radius: 8px; /* Rounded corners */
  text-decoration: none !important;
  border-bottom: none !important;
  .ant-tabs-tab-btn {
    color: #fff !important; /* Active tab text color */
    border-bottom: none !important;
    text-decoration: none !important;
  }
}
.class-view-btn .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #008c8c !important;
}
.class-view-btn .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #fff !important;
}
.Tabcontainer .class-view-btn .ant-tabs-tab-btn {
  color: #000000 !important;
}
.Tabcontainer .class-view-btn .ant-tabs-tab-active {
  background: #ffffff !important;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff !important;
  color: #000 !important;
  border-radius: 0% !important;
}
.class-view-btn .ant-tabs-tab + .ant-tabs-tab {
  margin: 0% !important;
  border-radius: 0% !important;
}
.Tabcontainer .class-view-btn .ant-tabs-nav-list {
  justify-content: flex-start !important;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff !important;
}
.Tabcontainer .class-view-btn .ant-tabs-nav {
  width: fit-content !important;
  background-color: transparent !important;
  box-shadow: 18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff !important;
  padding: 0px !important;
  height: fit-content !important;
  border-radius: 0% !important;
}
/* .class-view-btn {
  color: #000 !important;
} */
.Tabcontainer .ant-tabs-tab-bar {
  margin-bottom: 30px;
}
.Tabcontainer.ant-tabs-tab-btn:hover {
  text-decoration: none !important;
}
.Tabcontainer .ant-tabs-tab-active {
  text-decoration: none !important;
  border-bottom: none !important;
}
.Tabcontainer .ant-tabs .ant-tabs-tab {
  display: inline-flex;
}
.Tabcontainer .ant-tabs-nav-list {
  padding-top: 2px;
  margin-top: 2px;
  width: 100%;
  justify-content: space-between;
}
.Tabcontainer .ant-tabs-ink-bar {
  display: none;
}
