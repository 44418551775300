/* global.css */
:root {
    --primary-color: #3498db;
    --font-main: 'poppins';
}


body {
    font-family: 'poppins'!important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f9f9f9;
    color: #333;
}

a {
    text-decoration: none;
    color: inherit;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

button {
    cursor: pointer;
}

img {
    max-width: 100%;
    height: auto;
}


