
.ant-layout-sider.ant-layout-sider-children {
  font: 40px !important;
}
.ant-table-cell {
  font-family: "poppins" !important;
}
.ant-menu-item {
  font-family: "poppins" !important;
}
.ant-card-body {
  font-family: "poppins" !important;
}
.ant-table-thead > tr > th {
  background-color: #00adc4;
}
.ant-form-item-label {
  font-family: "poppins" !important;
  font-weight: 500;
  /* font-style: ; */
}
.ant-btn {
  font-family: "poppins" !important;
}
span {
  font-family: "poppins" !important;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}
.ant-select .ant-select-arrow {
  top: 40% !important;
}
.ant-table-content table {
  border-collapse: separate !important;
  border-spacing: 0 2px !important ;
  font-size: 14px;
}
.ant-table-content {
  font-size: 16px;
  font-weight: 500;
}
.ant-table-cell {
  background-color: #fff;
}

table {
  background-color: #ecf0f3;
}
.ant-table-thead > tr > th:first-child,
.ant-table-tbody > tr > td:first-child {
  border-radius: 5px 0 0 5px;
  margin-left: 5px;
  /* border: solid 1px #00ff5e; */
}

.ant-table-thead > tr > th:last-child,
.ant-table-tbody > tr > td:last-child {
  border-radius: 0 5px 5px 0;
  /* border: solid 1px #00ff5e; */
}
.ant-table-wrapper .ant-table-pagination-right {
  /* border: solid 1px #00ff5e; */
  justify-content: center;
  position: absolute;

  top: 105%;
  width: 100%;
}
.ant-pagination .ant-pagination-total-text {
  /* border: solid 1px rgb(72, 0, 255); */
  left: 0;
}

.ant-pagination {
  position: relative;
}
.ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
  font-size: "poppins";
}
.ant-pagination .ant-pagination-item {
  background-color: transparent;
  border: none;
}
.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  /* background-color: #ffffff; */
  border: none;
  background-color: #00adc4;
  border-radius: 5px;
  color: #fff !important;
}
.ant-table-wrapper .ant-pagination-center {
  justify-content: center !important;
  /* position: relative; */
  /* border: solid 1px #c8ff00; */
  width: 100%;
}

.ant-pagination-options {
  position: absolute;
  right: 0;
}
.ant-select-arrow {
  color: #000000 !important;
}
.ant-form-item .ant-form-item-label > label::after {
  content: "";
}
