/* .ant-picker-calendar-date{
    background-color: #00ADC4;
} */

.ant-picker-cell-inner
  .ant-picker-calendar-date
  .ant-picker-calendar-date-today {
  background-color: #00adc4;
}
/* .ant-statistic-title{
    color: #fff !important;
}
.ant-statistic-content{
    color: #fff !important;
    font-size: 40px !important;
} */

/* .carddetailscon{
    font-Size: '1rem';
     font-Weight: 'normal';
     color: '#fff';
} */

.ant-picker-cell-inner .ant-picker-calendar-date {
  background-color: #00adc4 !important;
  border-radius: 30px !important;
  color: #fff;
}

.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: none !important;
}
.ant-picker-calendar-date-value {
  /* background-color: #00ADC4 !important;
    border-radius: 30px !important;
    color: #fff; */
}
.ant-picker-cell-inner .ant-picker-calendar-date {
  background-color: #00adc4 !important;
  border-radius: 30px !important;
  color: #fff;
}
/* Default styles for larger screens */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.calendar-nav-btn {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  color: #0c8292;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  border: none;
  font-size: 16px;
}

.calendar-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
}
.calendar .ant-picker-calendar tbody tr:nth-child(n + 5) {
  display: none; /* Hide rows after the 4th */
}

/* Media query for tablets (768px and below) */
@media screen and (max-width: 768px) {
  .calendar-header {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .calendar-nav-btn {
    font-size: 14px;
    border-radius: 15px;
    padding: 8px;
  }
  tbody.ant-picker-body {
    height: 200px;
  }
  .calendar-title {
    font-size: 16px;
  }
}

/* Media query for mobile devices (480px and below) */
@media screen and (max-width: 480px) {
  .calendar-header {
    gap: 5px;
  }
  .ant-picker-body {
    height: 200px;
  }

  .calendar-nav-btn {
    font-size: 12px;
    padding: 5px;
  }

  .calendar-title {
    font-size: 14px;
  }
}

/* Limit the calendar to display only 4 rows */
.custom-calendar .ant-picker-calendar tbody tr:nth-child(n + 5) {
  display: none; /* Hide rows after the 4th */
}

/* Adjust navigation buttons styling */
.custom-calendar .calendar-nav-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(255, 255, 255);
  color: #0c8292;
  border-radius: 50%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  cursor: pointer;
}

.custom-calendar .calendar-nav-btn:hover {
  background-color: #f5f5f5;
}
