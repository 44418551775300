.Selectoptioncon{
    width: 122px;
    height: 30px;
    top: 435px;
    margin-left: 10rem;
    padding: 5px 10px 5px 10px;
    gap: 10px;
    opacity: 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #DADADA;
    border: none;
    
}